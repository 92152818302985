.mat-drawer-container {
  height: 100%;
  background: transparent !important;

  .mat-drawer-inner-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.side-bar-container {
  //padding: 10px 15px;
  //background-color: var(--color-sidebar-bg) !important;
  //color: #fff !important;
  //width: 250px;
  //padding: 10px 15px;
}

.navigation {
  padding: 0.5rem 1rem;
  .section-divider {
    display: block;
    border-top-width: 1px;
    border-top-style: solid;
    border-color: #ffffff26;
    margin: 10px 0;
  }

  .mat-list {
    height: 36px !important;
    font-size: 14px !important;

    .mat-list-item-content {
      padding: 0 !important;

      .list-item {
        color: #92929F;
        display: flex;
        align-items: center;
        transition: none;
        outline: none!important;
        height: 100%;
        padding: 0 1rem;
        flex: 0 0 100%;
        justify-content: space-between;

        .link-box {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          .icon {
            height: 1rem;
            width: 1rem;
            font-size: 1rem;
          }

          .name {
            white-space: nowrap;
          }
        }

        &.link {
          cursor: pointer;
        }

        &.is-active {
          color: #fff;
          background-color: var(--color-sidebar-active);
          border-radius: 4px;
        }

        &:hover:not(.is-active) {
          color: #fff;
        }
      }
    }
  }
}
