@use "sass:map";

button {
  cursor: pointer;
  font-family: 'Roboto', Helvetica, Arial, Tahoma, Verdana sans-serif;

  &.cdk-keyboard-focused {
    outline: 2px solid var(--color-outline);
  }
}


@mixin btn-mixin($text-color, $background-color, $opacity) {
  color: var(#{$text-color});
  background-color: $background-color;
  border-color: $background-color;

  &:hover {
    color: var(#{$text-color});
    background-color: darken($background-color, $opacity);
    border-color: darken($background-color, $opacity);
  }

  &:active {
    color: var(#{$text-color});
    background-color: darken($background-color, $opacity);
    border-color: darken($background-color, $opacity);
  }
}

.btn {
  position: relative;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 0.75rem 1.25rem;
  //margin: 0.5rem;
  border: 1px solid transparent;
  font-size: 0.875rem;
  line-height: 1.4;
  border-radius: 0.25rem;
  text-decoration: none;
  letter-spacing: 0.6px;
  transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;

  &:hover {
    color: var(--color-typography-dark);
    text-decoration: none;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.btn-sm {
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    padding: 0.25rem 1rem;
  }

  &.btn-lg {
    padding: 0.5rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
  }

  @include btn-mixin(--color-typography-medium, map.get($primary, "grey"), 20%);

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.btn-link {
  color: var(--color-secondary);
  background-color: transparent;
  border-color: transparent;

  &:hover {
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
  }
}

//.btn-light {
//  @include btn-mixin-darken(--color-gray-200, --color-typography-light, 20%);
//}
//
//.btn-dark {
//  @include btn-mixin(--color-typography-light, --color-gray-600, 20%);
//}
//
//.btn-darken {
//  @include btn-mixin(--color-typography-light, --color-gray-400, 20%);
//}

//.btn-primary {
//  @include btn-mixin(--color-typography-dark, --color-primary, 20%);
//}

//.btn-secondary {
//  @include btn-mixin(--color-typography-light, --color-attention-info, 20%);
//}
//
.btn-success {
  @include btn-mixin(--color-typography-light, map.get($attention, "success"), 5%);
}

.btn-alert {
  @include btn-mixin(--color-typography-light, map.get($attention, "error"), 5%);
}

//.btn-outline {
//  @include btn-outline-mixin(--color-typography-light, --color-typography-light, --color-gray-200, 20%);
//}
//
//.btn-outline-light {
//  @include btn-outline-mixin(--color-gray-200, --color-gray-200, --color-typography-light, 20%);
//}
//
//.btn-outline-dark {
//  @include btn-outline-mixin(--color-typography-light, --color-typography-light, --color-gray-600, 20%);
//}
//
//.btn-outline-primary {
//  @include btn-outline-mixin(--color-typography-light, --color-typography-dark, --color-primary, 40%);
//}
//
//.btn-outline-secondary {
//  @include btn-outline-mixin(--color-typography-light, --color-typography-light, --color-secondary, 20%);
//}
//
//.btn-outline-success {
//  @include btn-outline-mixin(--color-typography-light, --color-typography-light, --color-attention-success, 20%);
//}
//
//.btn-outline-alert {
//  @include btn-outline-mixin(--color-typography-light, --color-typography-light, --color-attention-error, 20%);
//}



.btn-group {
  position: relative;
  margin: 0.5rem;
  & > .btn,
  & > .dropdown,
  & > .btn.btn-sm,
  & > .btn.btn-lg {
    margin: 0;
    //border-radius: 0;

    position: static;
  }

  //& > .btn {
  //  border-width: 2px;
  //
  //  &:first-child {
  //    border-top-left-radius: 0.25rem;
  //    border-bottom-left-radius: 0.25rem;
  //  }
  //
  //  &:last-child {
  //    border-top-right-radius: 0.25rem;
  //    border-bottom-right-radius: 0.25rem;
  //  }
  //}

  & > .dropdown {
    .btn {
      border-radius: 0;
    }

    &:first-child {
      .btn {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }
    }

    &:last-child {
      .btn {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
    }
  }

  .dropdown-list {
    top: calc(100% - 2px);
  }
}




//@mixin btn-mixin-darken($text-color, $background-color, $opacity) {
//  color: var(#{$text-color});
//  background-color: var(#{$background-color});
//  border-color: var(#{$background-color});
//
//  &:hover {
//    color: var(#{$text-color});
//    background-color: var(#{$background-color}-outline);
//    border-color: var(#{$background-color}-outline);
//  }
//
//  &:active {
//    color: $text-color;
//    background-color: $background-color;
//    border-color: $background-color;
//  }
//}
//
//@mixin btn-outline-mixin($text-color, $alter-text-color, $background-color, $opacity) {
//  color: var(#{$text-color});
//  background-color: var(#{$background-color}-outline);
//  border-color: var(#{$background-color});
//
//  &:hover {
//    color: var(#{$alter-text-color});
//    background-color: var(#{$background-color});
//    border-color: var(#{$background-color});
//  }
//
//  &:active {
//    color: var(#{$alter-text-color});
//    background-color: var(#{$background-color}-active);
//    border-color: var(#{$background-color});
//  }
//}
