* {
  box-sizing: border-box;
}

html {
  height: 100%;
  min-height: 100vh;
  font-size: 16px;
  font-family: 'Inter', Helvetica, Arial, Tahoma, Verdana sans-serif;
  line-height: 1.15;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-overflow-scrolling: touch;

  overflow-y: auto;
  overflow-x: hidden;

  ////scrollbar-color: var(--color-primary) var(--color-gray-600);
  //scrollbar-width: thin;

  //&::-webkit-scrollbar {
  //  width: 4px;
  //  height: 4px;
  //}
  //
  //&::-webkit-scrollbar-track {
  //  background-color: var(--color-gray-600);
  //  border-radius: 100px;
  //}
  //
  //&::-webkit-scrollbar-thumb {
  //  border-radius: 100px;
  //  background: var(--color-primary);
  //}
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none !important;
  }

  &:focus {
    text-decoration: none;
    outline: none;
    -moz-outline-style: none;
  }
}

body {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  min-height: 100%;
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  background-size: 100%;
  background-color: var(--color-primary-bg);
  color: black;
  font-family: 'Inter', Helvetica, Arial, Tahoma, Verdana sans-serif;
  text-size-adjust: 100%;
  inset: 0;
  z-index: 1;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 !important;
  padding: 0 !important;
}

.visual-usd {
  position: absolute;
  right: 1.25rem;
  color: #8e8e8e;
}

.mat-divider {
  margin: 1rem 0 !important;
}

.mat-select-cases {
  min-height: 350px;

  .filter {
    padding: 0 16px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
    margin: 16px 0;

    .search-input {
      padding: 10px;
      width: 100%;
      border: 0;
      border-bottom: 1px solid grey;

      &:hover {
        border-bottom: 1px solid #3f51b5;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .cases-list {
    max-height: 275px;
    overflow: scroll;

  }
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.container {
  background: #fff;
  border-radius: 8px;
  //padding: 1rem;
  margin: 0.5rem 0;
}


