$attention: (
  success: #479F76,
  info: #0CC4FF,
  warning: #FFCD39,
  error: #D80027,
);

$notification: (
  success: #4FB007,
  info: #008FB5,
  warning: #ED9100,
  error: #DB193C,
);

$primary: (
  'accent': #3D8BFD,
  'green' : #479F76,
  'red': #E35D6A,
  'black': #333333,
  'grey': rgba(196, 196, 196, 0.2),
  'brown': rgba(254, 178, 114, 0.3),
  'bg': #f5f8fa,
  'sidebar': #1e1e2d,

  'clickable': #337AB7,
  'pending': #CFF4FC,
  'received': #D1E7DD,
  'reject': #F8D7DA,
  'inactive': rgba(51, 51, 51, 0.5),
);

$sidebar: (
  'bg': #1e1e2d,
  'active': #363650
);


$typography: (
  'light': #FFFFFF,
  'dark': #202225,
  'medium': #494B52,
  'muted-30': rgba(255, 255, 255, 0.3),
  'muted-50': rgba(255, 255, 255, 0.5),
  'muted-70': rgba(255, 255, 255, 0.7),
);


$colors: (
  attention: $attention,
  notification: $notification,
  primary: $primary,
  typography: $typography,
  sidebar: $sidebar
);


:root {
  @each $name, $color in $colors {
    @if (type-of($color) == 'map') {
      @each $subname, $subcolor in $color {
        @if ($subname == '') {
          --color-#{$name}: #{$subcolor};
        } @else {
          --color-#{$name}-#{$subname}: #{$subcolor};
        }
      }
    } @else if (type-of($color) == 'color') {
      --color-#{$name}: #{$color};
    }
  }
}
