@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,300;0,400;0,500;0,700;0,900;1,500&display=swap');
@import './assets/styles/main.scss';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.cdk-overlay-container {
  .cdk-global-overlay-wrapper {
    .cdk-overlay-pane {
      .mat-snack-bar-container {
        color: white;

        .mat-simple-snackbar-action {
          color: white;
        }

        &.success {
          background-color: #4caf50;
        }

        &.error {
          background-color: #ff7b7b;
        }

      }
    }
  }
}
